/* Contact Form Styles */
/* html {
    height: 100%;
    background: #141e30;
  } */
 
  
  .login-box {
    margin: 0 auto;
    width: 800px;
    padding: 40px;
    background: rgb(0 0 0 / 81%);
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    color: #fff;
    margin-bottom: 100px;
  }
  
  .login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
  }
  .login-box h1 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
  }
  
  .login-box .user-box {
    position: relative;
  }
  
  .login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent !important;
  }
  .login-box .user-box label  {
    position: absolute;
    top:0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
  }
  
  
  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color: #03e9f4;
    font-size: 12px;
  }

  
  
  .login-box form a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px
  }
  
  .login-box a:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }
  
  .login-box a span {
    position: absolute;
    display: block;
  }
  
  .login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
  }
  
  .login-box a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
  }
  
  .login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
  }
  
  
  .login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
  }
  
  /* KEYFRAMES */
  
  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }
  
  @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  /* USING KEYFRAMES */
  
  .login-box a span:nth-child(1) {
    animation: btn-anim1 1s linear infinite;
  }
  
  .login-box a span:nth-child(2) {
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
  }
  
  .login-box a span:nth-child(3) {
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
  }
  
  .login-box a span:nth-child(4) {
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
  }

 
  .login-box .user-box select {
    border: none;
    background: transparent;
    /* Add any other styles you want for the select element */
  }
  
  .login-box .user-box select:focus {
    border-bottom: 1px solid #03e9f4;
    /* Add any other styles for focus if needed */
  }
   .hidden-input {
    display: none;
  }
  .user-box input:focus ~ label {
    top: -20px;
    left: 0;
    color: #03e9f4;
    font-size: 12px;
  }
 
  
  .success-message,
.error-message {
    background: #8012e1; /* Green color for success message */
  color: #fff;
  padding: 10px;
  margin: 30px 0;
  text-align: center;
  border-radius: 5px;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition:  5000s ease-in-out 0s;
  width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent !important;
}

@media only screen and (max-width: 767px) {
  .login-box {
    margin: 0 auto;
    /* width: 800px; */
    padding: 40px;
    background: rgb(0 0 0 / 81%);
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 100px;
    /* margin: 25%; */
}
#contact{
  display: flex;
  display: flex;
  margin-left: 5px;
}
}



/* Medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-box {
    margin: 0 auto;
    /* width: 800px; */
    padding: 40px;
    background: rgb(0 0 0 / 81%);
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    color: #fff;
    margin-bottom: 100px;
    margin-top: 20px;
    margin-right: 10px !important;
    /* margin: 25%; */
}
#contact{
  display: flex;
  display: flex;
  margin-left: 5px;
}
}
/* For screens with exactly 1024px width */
@media only screen and (min-width: 1024px) and (max-width: 1024px) {
  /* Your styles for 1024px width go here */
}


@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

.footer-social-icons {
 
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column; /* Stack the items vertically */
  align-items: center;
  /* background: rgba(0, 0, 0, 0.7);  Semi-transparent black background */ 
  background-color: rgb(14, 13, 13);
  padding: 20px;
  color: #fff; /* Text color is white */
}

.social-icons {
  list-style: none; /* Remove bullets from list items */
  padding: 0; /* Remove default padding from list */
  display: flex;
}

.social-icons li {
  margin-right: 10px;
}

.social-icons a {
  color: #fff;
  text-decoration: none;
}

.fa {
  padding: 10px 14px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #322f30;
}


.fa-youtube:hover {
  background-color: #e71123;
}

.fa-soundcloud:hover {
    background-color: #d76a1c;
  }

  .fa-spotify:hover {
    background-color: #0cc221;
  }

  .fa-instagram:hover {
    background-color: #b62980;
  }


  .fa-twitter:hover {
    background-color: #3b71d5;
  }

  .fa-facebook:hover {
    background-color: #2b1dcb;
  }


.tiktok-icon {
    padding: 10px 14px;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #322f30;
  }
  
  
  
  
  
  
  .tiktok-icon:hover {
    /* TikTok Black */
    background-color: #ff0050;
  }


/* Medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Styles for medium devices */
  .login-box {
    margin: 0 auto;
    /* width: 800px; */
    padding: 40px;
    background: rgb(0 0 0 / 81%);
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    color: #fff;
    margin-bottom: 100px;
    /* margin: 25%; */
    margin-right: 15px;
}
.list-footer{
  font-size: 13px;
}
}

@media only screen and (max-width: 767px) {
 

  .footer-social-icons {
    align-items: center;
    background-color: rgb(14, 13, 13);
    padding: 20px;
    color: #fff;
    font-size: 20px;
    margin: auto;
    margin-left: 5px !important;
    border-radius: 10px;
}
.list-footer{
  font-size: 18px;
}
.social-icons li {
  margin-right: 2px;
  
}
}



/* Medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  


  


 .footer-social-icons {
    align-items: center;
    background-color: rgb(14, 13, 13);
    padding: 20px;
    color: #fff;
    font-size: 20px;
    margin: auto;
    margin-left: 5px !important;
    border-radius: 10px;
}
.list-footer{
  font-size: 15px;
}
}


/* Common styles for all screen sizes */
#calendar-container {
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
    background-color: rgba(0, 0, 0, 0.7); /* Black background with 70% opacity */
    color: white; /* White text color */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2); /* White box shadow with 20% opacity */
    margin-bottom: 20px;
  }
  
  .calendar-header {
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* Media query for small screens (max-width: 767px) */
  @media only screen and (max-width: 767px) {
    
.fc .fc-toolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
}
.fc .fc-daygrid-day-events {
    margin-top: 1px;
    font-size: 10px;

}
}
  /* Media query for medium screens (min-width: 768px) and (max-width: 991px) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #calendar-container {
      max-width: 600px; /* Adjust max-width for medium screens */
    }
.fc .fc-toolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 15px !important;
}
div.fc-toolbar-chunk{
   
}

.fc .fc-daygrid-day-events {
    margin-top: 1px;
    font-size: 10px;


}
  }
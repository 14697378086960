
/* Styling for the video component */
.video-container {
    display: flex;
    flex-direction: column; /* Change the flex direction to stack elements vertically */
    background: rgba(9, 10, 10, 0.7);
    color: white;
    font-weight: 700;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(6, 3, 28, 0.2);
    margin: 2em;
    align-items: center; /* Center horizontally within the container */
  }
  
  .video-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    flex: 1;
    text-align: center; /* Center the text and video */
  }
  
  /* .video-title {
    font-size: 35px;
    margin: 0;
    padding: 20px; 
  }
   */
  .video-text {
    font-size:35px;
    line-height: 1.5;
    margin-top: 20px;
  }
  
  .video-player {
    width: 1000px; /* Set the width you want */
    height: 600px; /* Set the height you want */
  }
  
/* Small devices (phones) */
@media only screen and (max-width: 767px) {
  /* Styles for small devices */
  .video-player {
   
      /* width: 1000px; */
      height: 600px;
      /* width: 800px !important; */
      /* margin: 25%; */
      width: auto;
      border-radius: 20px;
  }
  
  .video-container {
     display: block;
     
      
      align-items: center;
      margin: auto;
      padding: 10px;


}
.video-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  text-align: center;
  padding: 0px;

}
}

/* Medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Styles for medium devices */
  /* Styles for small devices */
  .video-player {
   
    /* width: 1000px; */
    height: 600px;
    /* width: 800px !important; */
    /* margin: 25%; */
    width: auto;
    border-radius: 20px;
}

.video-container {
   display: block;
   
    
    align-items: center;
    margin: auto;
    padding: 10px;


}
.video-content {
display: flex;
flex-direction: column;
align-items: center;
flex: 1 1;
text-align: center;
padding: 0px;

}
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) {
  /* Styles for large devices */
}



  
   
 *{
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-size: 100%;
  font-family: "Roboto";
  text-decoration: none;
  box-sizing: border-box;
}

body{
margin:0;
}




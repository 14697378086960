*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --white: rgba(255, 255, 255,1.0);
  --pink: rgb(64, 90, 218);
  --black: rgba(0, 0, 0, .8);
}

html{ font-size: 20px; }

body {
  font-family: 'Playfair Display', serif;
  background-image: url(../../public/images/pinkDjBackground.jpeg);
  background-size: contain;
  background-attachment: fixed; /* Optional: Keep the background fixed while scrolling */
  position: relative; /* Needed for the pseudo-element */
  background-color: #050f6b; /* Specify the desired background color */
  background-blend-mode: multiply; /* Adjust the blend mode as needed */
 
  
}

  
  body::before {
    content: "";
    background: rgba(75, 75, 80, 0.5); /* Overlay color with opacity */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Place the overlay behind other content */
  }
  
  /* Other CSS styles for your body */
  
  
  /* Other CSS styles for your body */
  

header {
    text-align: center;
    /* position: fixed;  */
    width: 100vw;
     /* background: var(--black); */
     
     color: white;
    font-size: 20px;
}

.logo{ 
  margin: 0; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo a{ 
  color: white;
}

a{ text-decoration: none; }

nav{
  position: absolute;
  text-align: left;
  top: 100%; 
  left: 0;
  background: var(--black);
  width: 100vw;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}

nav a{
  color: var(--white);
  font-size: 1rem;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

nav a:hover{ border-top: 2px dotted var(--pink); }

nav ul{
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

nav li{ margin: 1.2rem; }

/* .nav-toggle{ display: none; } */

.nav-toggle-label{
  position: absolute;
  top: 0;
  left: 0;
  margin-left: .8rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after{
  display: block;
  background: white;
  height: 1px;
  width: 35px;
  position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after{
  content: "";
  position: absolute;
}

 .nav-toggle-label span::before{ bottom: 7px; } 

.nav-toggle-label span::after{ top: 7px;}

.nav-toggle:checked ~ nav{ 
  transform: scale(1, 1);
} 

.nav-toggle:checked ~ nav a{
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}
@media screen and (min-width: 800px){
  .nav-toggle-label,
  .nav-toggle{
    display: none;
  }
  header{
    display: grid;
    grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr; 
  }
  .logo{
    grid-column: 2 / 3; 
  }
  nav{
     grid-column: 3 / 4; 
    all: unset;
  }
  nav a{
    opacity: 1;
  }
  nav ul{
    display: flex;
    justify-content: flex-end;
  }
}


/* Existing CSS styles */

/* Media query for screens with a maximum width of 480px (typical for cell phones) */
@media screen and (max-width: 480px) {
    
    .nav-toggle-label {
      /* Adjust the position and styles for the hamburger menu (which will be hidden) */
      display: none;

    }
  
    nav a {
      /* Hide the navigation links on small screens */
      display: none;
    }
  
    .logo {
        font-size: 60px;
      /* Position the website owner's name at the top */
      text-align: center;
      padding: 10px; /* Add some padding for better visibility */
    }
  
    
  }
  /* Small devices (phones) */
@media only screen and (max-width: 767px) {
  /* Styles for small devices */
  header {
    align-items: center;
    /* color: #e84393; */
    /* position: fixed; */
    text-align: center;
    background: black;
    color: white;
}
.nav-toggle-label {
  /* Adjust the position and styles for the hamburger menu (which will be hidden) */
  display: none;
  
}

nav a {
  /* Hide the navigation links on small screens */
  display: none;
}

.logo {
    font-size: 60px;
  /* Position the website owner's name at the top */
  text-align: center;
  padding: 10px; /* Add some padding for better visibility */
}

body {
  font-family: 'Playfair Display', serif;
  background-image: url(../../public/images/pinkDjBackground.jpeg);
  background-size: cover;
 
  background-color: #050f6b; /* Specify the desired background color */
  background-blend-mode: multiply; /* Adjust the blend mode as needed */
 
  background-repeat: no-repeat;
  margin:0;
 /* Prevent background image from repeating */
}

}




/* Medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Styles for medium devices */
  header {
    align-items: center;
    /* color: #e84393; */
    /* position: fixed; */
    text-align: center;
    background: black;
    color: white;
}
.nav-toggle-label {
  /* Adjust the position and styles for the hamburger menu (which will be hidden) */
  display: none;
  
}

nav a {
  /* Hide the navigation links on small screens */
  display: none;
}

.logo {
    font-size: 60px;
  /* Position the website owner's name at the top */
  text-align: center;
  padding: 10px; /* Add some padding for better visibility */
}
body {
  font-family: 'Playfair Display', serif;
  background-image: url(../../public/images/pinkDjBackground.jpeg);
  background-size: cover;
 
  background-color: #1b1818; /* Specify the desired background color */
  background-blend-mode: multiply; /* Adjust the blend mode as needed */
  background-repeat: no-repeat;
 /* Prevent background image from repeating */
}

}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) {
  /* Styles for large devices */
}
